import { PageProps } from 'gatsby';
import { ExtractData, Facia } from 'packages/innedit';
import React, { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Card from '~/components/List/Card/Extract';
import Item from '~/components/List/Item/Extract';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import IconPaste from '~/icons/Paste';
import extract from '~/params/note.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useNotes from '~/utils/useNotes';

const EspaceExtracts: FC<
  PageProps & EspaceProps & UserProps & { pageContext: { tab?: number } }
> = ({ espace, location, pageContext: { tab }, user }) => {
  const { t } = useTranslation();
  const items = useNotes({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  const handlePasteOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const text = await navigator.clipboard.readText();

    if (text) {
      try {
        const url = new URL(text);
        await toast.promise(
          Facia.call({
            kind: 'extract',
            params: {
              espaceId: espace.id,
            },
            q: url.href,
          }),
          {
            error: "Une erreur c'est produit lors de la création",
            pending: "En cours d'extraction de l'url",
            success: "L'extraction de l'url a réussi",
          },
        );
      } catch (error) {
        toast.error((error as Error).message);
      }
    }
  };

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemGrid={Card}
          itemList={Item}
          itemPathnamePrefix={`/espaces/${espace.id}/notes/extracts/`}
          menu={{
            right: [
              {
                icon: IconPaste,
                label: "Extraire l'URL qui se trouve dans le presse-papier",
                onClick: handlePasteOnClick,
              },
            ],
          }}
          model={
            new ExtractData({
              espaceId: espace.id,
              params: extract,
            })
          }
          pathname={location.pathname}
          removeAddButton
          search={location.search}
          subMenu={items}
          tabIndex={tab ?? 0}
          title={t('notes.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(EspaceExtracts);
